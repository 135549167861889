@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400..700;1,400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lavishly+Yours&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allison&family=Allura&family=Caveat+Brush&display=swap");

/* Global Components */

* {
  box-sizing: border-box;
}

.contact-btn {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: black;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
  border: 0px solid transparent;
  border-radius: 0.2rem;
  z-index: 2;
  cursor: pointer;
}

.submit-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: #000000;
  color: rgb(255, 255, 255);
  font-size: 14;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
  border: 0px solid transparent;
  border-radius: 0.5rem;
  z-index: 2;
  cursor: pointer;
  transition: 0.5s;
}

.submit-btn:hover {
  box-shadow: -6px 6px 4px #ffcbd2;
  transform: translateX(3px) translateY(-3px);
}

/* Global Components */

html {
  width: 100%;
  display: flex;
}

body {
  width: 100%;
  margin: 0;
  background-color: #ffcbd2;
}

.header {
  height: 3rem;
  padding: 0rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.329);
  background-color: white;
}

.header-title {
  font-family: "Allison", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.8rem;
}

/* Page */

.Page {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Hero */

.Hero {
  height: 100%;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.back-img {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero-overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.205);
}

/* stipe */
.stripe {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 0rem;
  background-color: #fae3e6;
}

.stripe > li {
  padding: 0;
  border-right: 1px solid black;
  list-style: none;
  width: 6rem;
  height: 4rem;
  text-align: center;
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.stripe > :last-child {
  border-right: none;
}

/* About-us */
.About-us {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  font-family: "Ibarra Real Nova", serif;
  margin-bottom: 2rem;
  color: #ffffff;
  z-index: 3;
}

.about-us-title {
  font-size: 1.9rem;
  font-weight: 500;
  margin: 0.6rem 0rem;
  text-align: center;
  font-family: "Allura", cursive;
}

.about-us-paragraph {
  width: 30ch;
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: center;
}

/* Products */
.Products {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.products-title {
  font-family: "Allura", cursive;
  font-size: 2rem;
  font-weight: 500;
}

.products-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5rem;
  gap: 1rem;
}

.bouncy-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;
}

.bouncy-house-container-half {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;
}

.floral-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;
}

.card {
  width: 20rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: 0.5s;
  background-color: #fae3e6;
  padding: 2rem;
}

.card-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
}

.card-img-container {
  height: 24rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.7rem;
}

.card-img {
  width: 16rem;
  height: 100%;
  object-fit: cover;
  border: 0px solid transparent;
}

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  position: absolute;
  bottom: 0.5rem;
}

.dot {
  width: 0.4rem;
  height: 0.4rem;
  border: 0px solid transparent;
  border-radius: 10rem;
  background-color: rgb(128, 128, 128);
  transition: 0.5s;
}

.dot-visible {
  background-color: rgb(255, 255, 255);
}

.overlay {
  width: 16rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.123);
  position: absolute;
  border: 0px solid transparent;
}

.card-title {
  width: 16rem;
  color: rgb(0, 0, 0);
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  margin: 0.2rem 0rem;
  font-weight: 500;
}

.card-description {
  width: 16rem;
  margin: 0rem 0rem 0.6rem 0rem;
  line-height: 1.6rem;
  font-weight: 400;
  font-size: 1rem;
}

.btn-change-img {
  width: 2rem;
  height: 15%;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid transparent;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
}

.arrow-icon {
  width: 1.4rem;
  color: black;
}

.left-arrow {
  rotate: 180deg;
}

/* Contact-us */
.Contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  padding: 5rem 0rem;
}

.contact-us-title {
  font-family: "Ibarra Real Nova", serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0.6rem 0rem;
}

.contact-form {
  width: 19.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border: 0.1rem solid rgba(0, 0, 0, 0.589);
  border-radius: 0.3rem;
  padding: 2rem 2rem;
  gap: 1.3rem;
  background-color: white;
}

.contact-form > label {
  font-family: "Inter", sans-serif;
  font-size: 15;
  font-weight: 500;
  margin-top: 0.5rem;
}

.contact-form > input {
  width: 100%;
  padding: 0.5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.397);
  border-radius: 0.4rem;
  font-family: "Inter", sans-serif;
}

.inquiry-input {
  width: 100%;
  padding: 0.5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.397);
  border-radius: 0.4rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: "Inter", sans-serif;
}

.contact-info {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 15;
  font-weight: 500;
  margin: 0;
  line-height: 1.5rem;
}

/* Footer */
.Footer {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.7rem;
  gap: 0.5rem;
}

.socials {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}

.socials > button {
  width: 3rem;
  height: 3rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border: 0px solid transparent;
  padding: 0;
  cursor: pointer;
}

.socials > a {
  width: 3rem;
  height: 3rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-background {
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  position: absolute;
  border: 0px solid transparent;
  border-radius: 50rem;
  z-index: 1;
}

.social-icon {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.Footer > p {
  font-family: "Inter", sans-serif;
  font-size: 0.7rem;
  margin: 0;
}

@media screen and (width > 700px) {
  .header {
    height: 4rem;
    padding: 0rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.329);
  }

  .header-title {
    font-size: 2.5rem;
  }

  /* Hero */
  .Hero {
    height: calc(80vh - 3rem);
    padding: 0rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }

  .hero-vector {
    width: 30rem;
    height: 30rem;
    position: absolute;
  }

  .about-us-title {
    font-size: 2.8rem;
  }

  .stripe > li {
    padding: 0;
    border-right: 1px solid black;
    list-style: none;
    width: 10rem;
    height: 4rem;
    font-size: 1rem;
  }

  /* cards */

  .products-title {
    margin-top: 4rem;
  }

  .pt1 {
    margin: 0;
  }

  .card {
    width: 30rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 4rem 2rem;
  }

  .card-img-container {
    height: 30rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
  }

  .card-img {
    width: 20rem;
    height: 100%;
    object-fit: cover;
    border: 0px solid transparent;
    border-radius: 0.2rem;
  }

  .overlay {
    width: 20rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.226);
    position: absolute;
    border: 0px solid transparent;
    border-radius: 0.2rem;
  }

  .card-title {
    width: 20rem;
    color: rgb(0, 0, 0);
    font-family: "Inter", sans-serif;
    font-size: 1.1rem;
    margin: 0.2rem 0rem;
    font-weight: 500;
  }

  .card-description {
    width: 20rem;
    margin: 0rem 0rem 0.6rem 0rem;
    line-height: 1.6rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .btn-change-img {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  /* about us */
  .about-us-paragraph {
    width: 40ch;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  /* contact */
  .contact-form {
    width: 25rem;
  }
}

@media screen and (width > 400px) and (height < 500px) {
  .hero-vector {
    width: 20rem;
    height: 20rem;
    position: absolute;
  }

  .Hero {
    height: 115vh;
    padding: 0rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
}

@media screen and (width > 1200px) {
  .btn-change-img {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  .floral-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .bouncy-house-container-half {
    flex-direction: row;
    align-items: flex-start;
  }

  .about-us-paragraph {
    width: 60ch;
  }

  .products-container {
    gap: 4rem;
  }
}

/* animation stuff */
.header,
.Hero {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust duration and timing function as needed */
}

.header.visible,
.Hero.visible {
  opacity: 1;
}

/* New styles for sliding and fading in */
.slide-in-left {
  opacity: 0; /* Start hidden */
  transform: translateX(-100px); /* Slide from the left */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Smooth transition */
}

.slide-in-left.visible {
  opacity: 1; /* End visible */
  transform: translateX(0); /* End at the original position */
}
